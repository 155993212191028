import { twMerge } from 'tailwind-merge';
import { Icon } from './icon';

type Props = {
  className?: string;
};

export function CircularProgress({ className }: Props) {
  return <Icon icon="spinner" spin className={twMerge('h-4 w-4 bg-transparent text-primary', className)} />;
}
